<!-- /*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/ -->
<section class="container position-relative mb-4 pb-5">
  <img
     appCdnUrl="assets/Icon/blue-square.svg"
    class="center-right-float vector z-3"
  />
  <img  appCdnUrl="assets/Icon/snake-vector.svg" class="left-bottom-float vector" />
  <div class="error-section">
    <div id="animationError"></div>
    <p class="font-24-16">
      Page not found<br />
      But the Phoenix can help you find what you’re looking for (redirect to
      <a routerLink="/" class="text-primary">home page</a>)
    </p>
  </div>
</section>
