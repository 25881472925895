/*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/
import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID, Renderer2, ChangeDetectorRef } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Router,
  RouterLink,
  RouterOutlet,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  ActivatedRoute,
} from '@angular/router';
import { FooterModule } from './components/footer/footer.module';
import { LoaderService } from './services/loader/loader.service';
import { LoaderComponent } from './components/loader/loader.component';
import { AOSService } from './services/AOSanimation/aos.service';
import { HeaderComponent } from './components/header/header.component';
import { filter, Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterLink,
    RouterOutlet,
    HeaderComponent,
    FooterModule,
    LoaderComponent
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  private routerSubscription: Subscription | undefined;
  private routerEventsSubscription!: Subscription;
  // private hasReloaded:boolean = false;
  // isBrowser: boolean;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object,
    private loaderService: LoaderService,
    private router: Router,
    private aosService: AOSService,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentUrl = this.router.url;
        const canonicalUrl = 'https://www.fbspl.com' + currentUrl;
        this.setCanonicalURL(canonicalUrl);
      });

    this.addJsonLdScript();

    this.loaderService.show();

    if (isPlatformBrowser(this.platformId)) {
      this.aosService.init();
      this.setupScrollListener();

      // Event listener for window load
      window.addEventListener('load', this.onWindowLoad);

    }

    // Subscribe to router events
    this.routerSubscription = this.router.events.subscribe((event) => {
      // console.clear();
      if (isPlatformBrowser(this.platformId)) {
        if (event instanceof NavigationStart) {
          this.loaderService.show();
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.checkResourcesLoaded();
          this.addJsonLdScript(); // Re-add schema for new page
        }
      }
    });

    this.routerEventsSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart || event instanceof NavigationEnd ||
        event instanceof NavigationCancel || event instanceof NavigationError) {
        this.removeModalBackdrop();
      }
    });

  }
  setCanonicalURL(url: string): void {
    const head = this.document.head;
    let linkElement: HTMLLinkElement | null = this.document.querySelector('link[rel="canonical"]');

    if (!linkElement) {
      linkElement = this.document.createElement('link');
      linkElement.setAttribute('rel', 'canonical');
      this.renderer.appendChild(head, linkElement);
    }

    linkElement.setAttribute('href', url);
    this.cdr.detectChanges(); 
  }
  setupScrollListener(): void {
    if (isPlatformBrowser(this.platformId)) {
      let prevScrollPos = window.pageYOffset;

      window.onscroll = () => {
        const currentScrollPos = window.pageYOffset;
        this.handleScroll(currentScrollPos, prevScrollPos);
        this.subHandleScroll(currentScrollPos, prevScrollPos);
        prevScrollPos = currentScrollPos;
      };

      const navMain = this.document.getElementById('navbarCollapse');
      if (navMain) {
        navMain.onclick = () => {
          if (navMain.classList.contains('show')) {
            navMain.classList.remove('show');
          }
        };
      }
      const subNavMain = this.document.getElementById('subNavbarCollapse');
      if (subNavMain) {
        subNavMain.onclick = () => {
          if (subNavMain.classList.contains('show')) {
            subNavMain.classList.remove('show');
          }
        };
      }
    }
  }

  subHandleScroll(currentScrollPos: number, prevScrollPos: number): void {
    // ... (keep your subHandleScroll implementation)
  }

  handleScroll(currentScrollPos: number, prevScrollPos: number): void {
    // ... (keep your handleScroll implementation)
  }

  checkResourcesLoaded(): void {
    if (isPlatformBrowser(this.platformId) && document.readyState === 'complete') {
      this.loaderService.hide();
      this.hideGlobalLoader();
    }
  }

  onWindowLoad = (): void => {
    if (isPlatformBrowser(this.platformId)) {
      this.hideGlobalLoader();
      this.loaderService.hide();
    }
  };

  private removeModalBackdrop(): void {
    if (isPlatformBrowser(this.platformId)) {
      const modalBackdrop = document.querySelector('.modal-backdrop.show');
      if (modalBackdrop) {
        modalBackdrop.remove();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (isPlatformBrowser(this.platformId)) {
      window.removeEventListener('load', this.onWindowLoad);
    }
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  hideGlobalLoader(): void {
    if (isPlatformBrowser(this.platformId)) {
      const globalLoader = this.document.getElementById('globalLoader');
      if (globalLoader) {
        globalLoader.classList.add('hidden');
      }
    }
  }

  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
        const serviceScript = this.renderer.createElement('script');
        serviceScript.type = 'application/ld+json';
        serviceScript.text = JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "FBSPL",
          "url": "https://www.fbspl.com",
          "logo": "https://d1585q1wairh36.cloudfront.net/assets/Logo/Logo.svg",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+1-240-979-0061",
            "contactType": "sales",
            "areaServed": ["US", "GB", "CA"],
            "availableLanguage": "en"
          },
          "sameAs": [
            "https://www.linkedin.com/company/fbsplteam",
            "https://www.youtube.com/@FBSPLTeam",
            "https://www.instagram.com/fbsplteam/",
            "https://www.fbspl.com/",
            "https://www.facebook.com/FBSPLTeam",
            "https://twitter.com/FBSPLTeam"
          ]
        });
        this.renderer.appendChild(this.document.head, serviceScript);
      }
    }
  }

  // private addAlternateLinks() {
  //   const alternateLinkDefault = this.renderer.createElement('link');
  //   alternateLinkDefault.rel = 'alternate';
  //   alternateLinkDefault.href = `https://www.fbspl.com`;
  //   alternateLinkDefault.hreflang = 'x-default';
  //   this.renderer.appendChild(this.document.head, alternateLinkDefault);

  //   const alternateLinkEn = this.renderer.createElement('link');
  //   alternateLinkEn.rel = 'alternate';
  //   alternateLinkEn.href = `https://www.fbspl.com/`;
  //   alternateLinkEn.hreflang = 'en-US';
  //   this.renderer.appendChild(this.document.head, alternateLinkEn);
  // }
}
