/*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/
import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { SeoService } from '../../services/seo/seo.service';
import { SeoData } from '../../services/seo/seo-data.model';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { CdnUrlDirective } from '../../directives/cdn-url.directive';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-securityMeasure',
  standalone: true,
  imports: [RouterLink, CdnUrlDirective],
  templateUrl: './securityMeasure.component.html',
  styleUrl: './securityMeasure.component.css',
})
export class securityMeasureComponent implements OnInit {
  constructor(
    private meta: Meta,
    private seoService: SeoService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private renderer: Renderer2
  ) {}
  ngOnInit(): void {
    // this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Security Measures | FBSPL',
      description:
        'FBSPL prioritizes data security with robust technical, operational, and managerial controls. Learn about CIA triad implementation, disaster recovery, and how we safeguard your information.',
      keywords: 'Security Measures, data protection, data security',
      ogImage: '/Security-Measures/SecurityMeasuresBanner.jpg',
    };
    this.seoService.setMetaTags(seoData);
    // this.meta.addTag({
    //   rel: 'canonical',
    //   href: 'https://www.fbspl.com/security-measures',
    // });
  }
  // private addJsonLdScript() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     const alternateLinkDefault = this.renderer.createElement('link');

  //     alternateLinkDefault.rel = 'alternate';
  //     alternateLinkDefault.href = `https://www.fbspl.com`;
  //     alternateLinkDefault.hreflang = 'x-default';
  //     this.renderer.appendChild(this.document.head, alternateLinkDefault);

  //     const alternateLinkEn = this.renderer.createElement('link');
  //     alternateLinkEn.rel = 'alternate';
  //     alternateLinkEn.href = `https://www.fbspl.com/security-measures`;
  //     alternateLinkEn.hreflang = 'en-us';
  //     this.renderer.appendChild(this.document.head, alternateLinkEn);
  //   }
  // }
}
