/*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class SubGuideService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getSubBlogData(slug: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/api/guide/detail/${slug}`
    );
  }
}
