/*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/
import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  ElementRef,
  ViewChild,
  PLATFORM_ID,
  Inject,
  Renderer2,
} from '@angular/core';
import { TrustedModule } from '../../components/HomeComponents/trustedSlider/trusted.module';
import { SubmitBtn } from '../../components/SubmitBtn/SubmitBtn.component';
import { RouterLink } from '@angular/router';
import { SeoService } from '../../services/seo/seo.service';
import { SeoData } from '../../services/seo/seo-data.model';
import { VimeoPlayerComponent } from '../../components/vimeo-player/vimeo-player.component';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { CdnUrlDirective } from '../../directives/cdn-url.directive';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [
    TrustedModule,
    RouterLink,
    VimeoPlayerComponent,
    CommonModule,
    CdnUrlDirective,
    SubmitBtn,
    SlickCarouselModule,
  ],
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
})
export class AboutUsComponent implements OnInit, OnDestroy {
  @ViewChild('AboutVision', { static: false }) AboutVision!: ElementRef;
  cdnUrl = environment.cdnUrl;
  currentSection: 'Mission' | 'Vision' | 'Values' = 'Vision';
  private sections: ('Mission' | 'Vision' | 'Values')[] = [
    'Mission',
    'Vision',
    'Values',
  ];
  private sectionIndex = 0;
  private intervalId: any;
  showText = true;

  hideText() {
    this.showText = false;
  }
  constructor(
    private seoService: SeoService,
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: object,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'About FBSPL | Global Business Process Outsourcing & Management',
      description:
        'We are a global business process management and outsourcing company offering expert services powered by technology to improve efficiency and drive growth.',
      keywords: 'business process outsourcing, outsourcing business',
      ogImage: '/meta/about.jpg',
    };

    this.seoService.setMetaTags(seoData);
    this.startSectionRotation();
  }

  private addJsonLdScript() {
    // Check and add BreadcrumbList script
    if (
      !this.document.head.querySelector(
        'script[type="application/ld+json"][data-script="breadcrumb"]'
      )
    ) {
      const breadcrumbScript = this.renderer.createElement('script');
      breadcrumbScript.type = 'application/ld+json';
      breadcrumbScript.setAttribute('data-script', 'breadcrumb');
      breadcrumbScript.text = `
    { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "About FBSPL", 

      "item": "https://www.fbspl.com/about-who-we-are" 

    } 

  ] 

} `;
      this.renderer.appendChild(this.document.head, breadcrumbScript);
    }
  }

  ngOnDestroy(): void {
    this.clearSectionRotation();
  }

  setSection(section: 'Mission' | 'Vision' | 'Values') {
    this.currentSection = section;
  }
  setSectiononClick(section: 'Mission' | 'Vision' | 'Values') {
    if (isPlatformBrowser(this.platformId)) {
      const isMobileView = window.innerWidth <= 768;

      if (isMobileView) {
        this.AboutVision.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          top: '-20',
        });
      }
    }

    this.currentSection = section;
  }
  private startSectionRotation(): void {
    this.ngZone.runOutsideAngular(() => {
      this.intervalId = setInterval(() => {
        this.ngZone.run(() => {
          if (this.sections[this.sectionIndex] !== undefined) {
            this.setSection(this.sections[this.sectionIndex]);
            this.sectionIndex = (this.sectionIndex + 1) % this.sections.length;
          } else {
            this.clearSectionRotation();
          }
        });
      }, 4000);
    });
  }

  private clearSectionRotation(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  scrollToSection(sectionId: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }

  Glimpse1 = [
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_1.jpg`,
      alt: ' FBSPL team-building activity',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_2.jpg`,
      alt: 'Group selfie',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_3.jpg`,
      alt: 'FBSPL staff enjoying lunch in the canteen',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_4.jpg`,
      alt: ' Well-furnished FBSPL canteen with employees dining',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_5.jpg`,
      alt: 'Team members brainstorming in a meeting at FBSPL',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_6.jpg`,
      alt: 'FBSPL employees collaborating in the office',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_7.jpg`,
      alt: 'FBSPL gym',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_8.jpg`,
      alt: 'FBSPL team celebrating a project success',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_9.jpg`,
      alt: '-FBSPL team in office',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_10.jpg`,
      alt: 'Team members working on a project',
    },
  ];
  GlimpseConfig1 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    draggable: false,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    pauseOnFocus: false,
    swipe: false,
    touchMove: false,
  };
  Glimpse2 = [
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_2.jpg`,
      alt: 'Group selfie',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_3.jpg`,
      alt: 'FBSPL staff enjoying lunch in the canteen',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_4.jpg`,
      alt: 'Well-furnished FBSPL canteen with employees dining',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_5.jpg`,
      alt: 'Team members brainstorming in a meeting at FBSPL',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_6.jpg`,
      alt: 'FBSPL employees collaborating in the office',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_7.jpg`,
      alt: 'FBSPL gym',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_8.jpg`,
      alt: 'FBSPL team celebrating a project success',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_9.jpg`,
      alt: '-FBSPL team in office',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_10.jpg`,
      alt: 'Team members working on a project',
    },

    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_1.jpg`,
      alt: 'FBSPL team-building activity',
    },
  ];
  GlimpseConfig2 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    draggable: false,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    pauseOnFocus: false,
    swipe: false,
    touchMove: false,
  };
  Glimpse3 = [
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_3.jpg`,
      alt: 'FBSPL staff enjoying lunch in the canteen',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_4.jpg`,
      alt: 'Well-furnished FBSPL canteen with employees dining',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_5.jpg`,
      alt: 'Team members brainstorming in a meeting at FBSPL',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_6.jpg`,
      alt: 'FBSPL employees collaborating in the office',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_7.jpg`,
      alt: 'FBSPL gym',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_8.jpg`,
      alt: 'FBSPL team celebrating a project success',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_9.jpg`,
      alt: 'FBSPL team in office',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_10.jpg`,
      alt: 'Team members working on a project',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_1.jpg`,
      alt: 'FBSPL team-building activity',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_2.jpg`,
      alt: 'Group selfie',
    },
  ];
  GlimpseConfig3 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    draggable: false,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    pauseOnFocus: false,
    swipe: false,
    touchMove: false,
  };
  Glimpse4 = [
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_4.jpg`,
      alt: 'Well-furnished FBSPL canteen with employees dining',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_5.jpg`,
      alt: 'Team members brainstorming in a meeting at FBSPL',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_6.jpg`,
      alt: 'FBSPL employees collaborating in the office',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_7.jpg`,
      alt: 'FBSPL gym',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_8.jpg`,
      alt: 'FBSPL team celebrating a project success',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_9.jpg`,
      alt: 'FBSPL team in office',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_10.jpg`,
      alt: 'Team members working on a project',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_1.jpg`,
      alt: 'FBSPL team-building activity',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_2.jpg`,
      alt: 'Group selfie',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_3.jpg`,
      alt: 'FBSPL staff enjoying lunch in the canteen',
    },
  ];
  GlimpseConfig4 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    draggable: false,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    pauseOnFocus: false,
    swipe: false,
    touchMove: false,
  };
  Glimpse5 = [
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_5.jpg`,
      alt: 'Team members brainstorming in a meeting at FBSPL',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_6.jpg`,
      alt: 'FBSPL employees collaborating in the office',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_7.jpg`,
      alt: 'FBSPL gym',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_8.jpg`,
      alt: 'FBSPL team celebrating a project success',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_9.jpg`,
      alt: 'FBSPL team in office',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_10.jpg`,
      alt: 'Team members working on a project',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_1.jpg`,
      alt: 'FBSPL team-building activity',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_2.jpg`,
      alt: 'Group selfie',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_3.jpg`,
      alt: 'FBSPL staff enjoying lunch in the canteen',
    },
    {
      img: `${this.cdnUrl}assets/life@fbspl/Image_4.jpg`,
      alt: 'Well-furnished FBSPL canteen with employees dining',
    },
  ];
  GlimpseConfig5 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    draggable: false,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    pauseOnFocus: false,
    swipe: false,
    touchMove: false,
  };
}
