/*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/
import { Component, OnInit, Inject, PLATFORM_ID,
  Renderer2 } from '@angular/core';

import { RouterLink } from '@angular/router';
import { SeoService } from '../../services/seo/seo.service';
import { SeoData } from '../../services/seo/seo-data.model';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { CdnUrlDirective } from '../../directives/cdn-url.directive';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-PrivacyPolicy',
  standalone: true,
  imports: [RouterLink, CdnUrlDirective],
  templateUrl: './PrivacyPolicy.component.html',
  styleUrl: './PrivacyPolicy.component.css',
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(
    private meta: Meta,
    private seoService: SeoService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private renderer: Renderer2
  ) {}
  ngOnInit(): void {
  // this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Privacy Policy | FBSPL',
      description:
        "Read FBSPL's Privacy Policy to understand how we collect, use, and protect your personal information. Your privacy is our priority.",
      keywords: 'Privacy Policy, Data Protection, User Privacy',
      ogImage: '/Privacy-Policy/Privacy Banner.jpg'
    };
    this.seoService.setMetaTags(seoData);
    // this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/privacy-policy' });
  }
}
