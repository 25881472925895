<!-- /*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/ -->
<main>
  <div class="container d-none d-lg-block">
    <div class="gradientBanner">
      <img
        appCdnUrl="assets/guides/Guide-Banner1.jpg"
        class="desktop-banner"
        alt="FBSPL Blogs & Latest Trends"
      />
      <div class="bannerText" data-aos="slide-up">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="./">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Guides</li>
          </ol>
        </nav>
        <h1 class="font-48-30 text-primary pt-3">Guides</h1>
        <p class="font-20 mt-3">
          Discover expert-crafted guides that delve into insurance, accounting,
          data annotation, and more. Gain practical insights and strategies to
          support your growth and keep your business a step ahead.
        </p>
      </div>
    </div>
  </div>
  <div class="container text-dark slide d-block d-lg-none pt-5">
    <div class="gradientBannerMob">
      <img
        appCdnUrl="assets/guides/Guide-MobileBanner1.jpg"
        class="card-img-mob"
        alt="FBSPL Blogs & Latest Trends "
      />
      <div class="bannerTextMob" data-aos="slide-up">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="./">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Guides</li>
          </ol>
        </nav>
        <h1 class="font-48-30 text-primary">Guides</h1>
        <p class="font-20 mt-3">
          Discover expert-crafted guides that delve into insurance, accounting,
          data annotation, and more. Gain practical insights and strategies to
          support your growth and keep your business a step ahead.
        </p>
      </div>
    </div>
  </div>

  <!-- <section class="container py-5">
      <h3 class="font-48-30 text-primary">Featured</h3>
      <ngx-slick-carousel
        class="carousel blog_slider"
        #slickModal="slick-carousel"
        [config]="slideConfig"
        >
        <div class="container" ngxSlickItem *ngFor="let slides of featuredBlogs">
          <div class="row">
            <div class="col-12 col-md-12 my-3">
              <a [routerLink]="['/guides', slides.slug]">
                <div class="card blog-cards">
                  <img
                    [src]="slides.feature_image"
                    alt="{{ slides.feature_alt_tag }}"
                    class="blog-cards-image"
                    />
                    <div class="description">
                      <h4 class="font-28-20" [title]="slides.title">
                        {{ slides.title }}
                      </h4>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </ngx-slick-carousel>
      </section> -->
  <section class="container py-4" id="top" #top>
    <div class="row">
      <h3 class="font-48-30 text-primary col-12 col-lg-4 my-3">Guides</h3>
      <div
        class="col-12 col-lg-8 d-flex justify-content-end row Guides_maain_input"
      >
        <div class="col-12 my-3 px-0 mx-0 d-flex justify-content-center">
          <div class="search-container ms-auto">
            <input
              type="text"
              placeholder="Enter your Keywords"
              id="search-input"
              [(ngModel)]="searchTerm"
              (keyup.enter)="onSearch()"
            />
            <button type="submit" id="search-button" (click)="onSearch()">
              <img appCdnUrl="assets/Icon/search.svg" alt="Search" />
            </button>
          </div>
        </div>
        <!-- <div class="dropdown col-6 col-md-3 my-3">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >
                By Services
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a class="dropdown-item" href="./guide#insurance">Insurance</a>
                </li>
                <li>
                  <a class="dropdown-item" href="./guide#accountingandbookkeeping"
                    >Accounting & Bookkeeping</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item" href="./guide#ecommerce">E-commerce</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="./guide#recruitment"
                      >Recruitment</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="./guide#customerSupport"
                        >Customer Support</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="./guide#dataAnnotation"
                          >Data Annotation</a
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="./guide#insuranceagencyoptimization"
                            >Insurance Agency Optimization</a
                            >
                          </li>
                          <li>
                            <a class="dropdown-item" href="./guide#digitalmarketing"
                              >Digital Marketing</a
                              >
                            </li>
                            <li><a class="dropdown-item" href="./guide#other">Other</a></li>
                            <li>
                              <a class="dropdown-item" href="./guide">All</a>
                            </li>
                          </ul>
                        </div>
                        <div class="dropdown col-6 col-md-3 my-3">
                          <button
                            class="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            >
                            By Industries
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                              <a class="dropdown-item" href="./guide#ecommerce">E-commerce</a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="./guide#insurance">Insurance</a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="./guide#realestate">Real estate</a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="./guide">All</a>
                            </li>
                          </ul>
                        </div> -->
      </div>
    </div>
    @if (!APIerror) {
    <div class="row mt-5">
      @for (blog of blogData; track blog) {
      <div class="col-12 col-md-6 my-3" data-aos="zoom-in">
        <a [routerLink]="['/guides', blog.slug]">
          <div class="card blog-cards">
            <img
              [src]="blog.feature_image"
              alt="{{ blog.feature_alt_tag }}"
              class="blog-cards-image"
            />
            <div class="description">
              <!-- <p class="fw-bold">{{ blog.blog_category.name }}</p> -->
              <h4 class="font-28-20" [title]="blog.title">
                {{ blog.title }}
              </h4>
            </div>
          </div>
        </a>
      </div>
      }
    </div>
    } @if (APIerror) {
    <div>
      <h4 class="text-red font-36-24 text-center my-5">
        {{ Errormessage }}
      </h4>
    </div>
    } @if (pages.length >= 1) {
    <div class="row py-5">
      <nav>
        <ul class="pagination pagination-lg mb-0 place-content-center">
          <li
            class="page-item"
            aria-current="page"
            (click)="changePage(currentPage - 1)"
            [hidden]="currentPage === 1"
          >
            <span class="page-link px-3"> Previous</span>
          </li>
          @for (page of pages; track page) {
          <li
            class="page-item active"
            aria-current="page"
            (click)="changePage(page)"
            [ngClass]="{
              active: page === currentPage
            }"
          >
            <span class="page-link px-3"> {{ page }}</span>
          </li>
          }
          <li
            class="page-item"
            aria-current="page"
            (click)="changePage(currentPage + 1)"
            [hidden]="currentPage === pages[pages.length - 1]"
          >
            <span class="page-link px-3"> Next</span>
          </li>
        </ul>
      </nav>
    </div>
    }
  </section>
</main>
