<!-- /*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/ -->
<div class="container d-none d-lg-block">
  <div class="gradientBanner">
    <img appCdnUrl="assets/contact-us/ContactUs.jpg" class="desktop-banner" />
    <div class="bannerText" data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Contact us</li>
        </ol>
      </nav>
      <h1 class="font-48-30 pt-3">Contact us</h1>
      <h2 class="font-20-16 font-normal pt-2">
        What can we do to make things easier for you?
      </h2>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none pt-5">
  <div class="gradientBannerMob">
    <img
      appCdnUrl="assets/contact-us/ContactUsMobile.jpg"
      class="card-img-mob"
    />
    <div class="bannerTextMob" data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Contact us</li>
        </ol>
      </nav>
      <h1 class="font-48-30 pt-3">Contact us</h1>
      <h2 class="font-20-16 font-normal pt-2">
        What can we do to make things easier for you?
      </h2>
    </div>
  </div>
</div>

<section class="container pt-5 position-relative" data-aos="slide-up">
  <img
    appCdnUrl="assets/Icon/blue-square.svg"
    class="center-right-float vector"
  />
  <h2 class="text-primary font-48-30 pt-3">
    Let’s get started with great things!
  </h2>
  <p class="font-24-16 mb-md-5 my-4">
    Please select your area of interest and we will schedule a discussion with
    you
  </p>
</section>
<section class="bg-secondary-30" data-aos="slide-up">
  <div class="container slider py-2">
    <span
      class="font-34-19 me-5 slider-text"
      [class.active]="activeSlide === 'Alliances & Partnerships'"
      (click)="updateContent('Alliances & Partnerships')"
      >Alliances & Partnerships</span
    >
    <span
      class="font-34-19 me-5 slider-text"
      [class.active]="activeSlide === 'Careers'"
      (click)="updateContent('Careers')"
      >Careers</span
    >
    <span
      class="font-34-19 slider-text"
      [class.active]="activeSlide === 'Media & General Query'"
      (click)="updateContent('Media & General Query')"
      >Any other query
    </span>
  </div>
</section>

<section class="container position-relative py-3 py-xl-5">
  <img
    appCdnUrl="assets/Icon/pyramidsvector.svg"
    class="d-lg-block d-none bottom-left-float vector"
  />

  @if (activeSlide === 'Alliances & Partnerships') {
  <div class="row">
    <div
      class="col-lg-4 d-flex align-items-center justify-content-center py-3 d-none d-lg-block"
    >
      <div class="box">
        <img
          appCdnUrl="assets/contact-us/Alliances & Partnerships.jpg"
          class="img-fluid"
        />
      </div>
    </div>
    <div class="col-12 col-lg-8 py-3">
      <p class="font-20">
        We are more than happy to forge any collaboration that fosters mutual
        growth. If you're also looking for such opportunities, please get in
        touch with us.
      </p>
      <form
        autocomplete="off"
        [formGroup]="AlliancesPartnerships"
        (ngSubmit)="validateFormPartnerForm(AlliancesPartnerships)"
      >
        <div class="row">
          <div class="col-md-6">
            <div class="mt-3">
              <input
                type="text"
                class="lets-form"
                placeholder="Name"
                appNoNumbers
                formControlName="name"
                [ngClass]="{
                  'is-invalid':
                    PartnerFormsubmitted &&
                    AlliancesPartnershipsf['name'].errors
                }"
              />
              @if (PartnerFormsubmitted &&
              AlliancesPartnershipsf['name'].errors) {
              <div class="invalid-feedback">
                @if ( AlliancesPartnershipsf['name'].errors[
                'requiredButNotAllowBlank' ] ) {
                <div>*This Field is Required</div>
                }
              </div>
              }
            </div>
            <div class="mt-3">
              <select
                class="form-select lets-form"
                formControlName="position"
                [ngClass]="{
                  'is-invalid':
                    PartnerFormsubmitted &&
                    AlliancesPartnershipsf['position'].errors
                }"
              >
                <option value="" disabled selected hidden>Position</option>
                <option value="Business Owner">Business Owner</option>
                <option value="C-level Executive">C-level Executive</option>
                <option value="Freelance / Consultant">
                  Freelance / Consultant
                </option>
              </select>
              @if (PartnerFormsubmitted &&
              AlliancesPartnershipsf['position'].errors) {
              <div class="invalid-feedback">
                @if ( AlliancesPartnershipsf['position'].errors[
                'requiredButNotAllowBlank' ] ) {
                <div>*This Field is Required</div>
                }
              </div>
              }
            </div>
            <div class="mt-3">
              <select
                class="form-select lets-form"
                formControlName="country"
                [ngClass]="{
                  'is-invalid':
                    PartnerFormsubmitted &&
                    AlliancesPartnershipsf['country'].errors
                }"
              >
                <option value="" disabled selected hidden>Country</option>
                <option value="India">India</option>
                <option value="Canada">Canada</option>
                <option value="US">US</option>
                <option value="Australia">Australia</option>
              </select>
              @if (PartnerFormsubmitted &&
              AlliancesPartnershipsf['country'].errors) {
              <div class="invalid-feedback">
                @if (AlliancesPartnershipsf['country'].errors['required']) {
                <div>*This Field is Required</div>
                }
              </div>
              }
            </div>
            <div class="mt-3">
              <select
                class="form-select lets-form"
                formControlName="partnership"
                [ngClass]="{
                  'is-invalid':
                    PartnerFormsubmitted &&
                    AlliancesPartnershipsf['partnership'].errors
                }"
              >
                <option value="" disabled selected hidden>Partnership</option>
                <option value="Agency">Agency</option>
                <option value="Solution Partner">Solution Partner</option>
                <option value="Technology">Technology</option>
              </select>
              @if ( PartnerFormsubmitted &&
              AlliancesPartnershipsf['partnership'].errors ) {
              <div class="invalid-feedback">
                @if ( AlliancesPartnershipsf['partnership'].errors[
                'requiredButNotAllowBlank' ] ) {
                <div>*This Field is Required</div>
                }
              </div>
              }
            </div>
          </div>
          <div class="col-md-6">
            <div class="mt-3">
              <input
                type="email"
                class="lets-form"
                formControlName="email"
                placeholder="Email address"
                [ngClass]="{
                  'is-invalid':
                    PartnerFormsubmitted &&
                    AlliancesPartnershipsf['email'].errors
                }"
              />
              @if (PartnerFormsubmitted &&
              AlliancesPartnershipsf['email'].errors) {
              <div class="invalid-feedback">
                @if ( AlliancesPartnershipsf['email'].errors[
                'requiredButNotAllowBlank' ] ) {
                <div>*This Field is Required</div>
                } @if (AlliancesPartnershipsf['email'].errors['email']) {
                <div>Invalid email address</div>
                }
              </div>
              }
            </div>
            <div class="mt-3">
              <input
                type="text"
                class="lets-form"
                formControlName="companyName"
                placeholder="Company Name"
                [ngClass]="{
                  'is-invalid':
                    PartnerFormsubmitted &&
                    AlliancesPartnershipsf['companyName'].errors
                }"
              />
              @if ( PartnerFormsubmitted &&
              AlliancesPartnershipsf['companyName'].errors ) {
              <div class="invalid-feedback">
                @if ( AlliancesPartnershipsf['companyName'].errors[
                'requiredButNotAllowBlank' ] ) {
                <div>*This Field is Required</div>
                }
              </div>
              }
            </div>
            <div class="mt-3">
              <select
                class="form-select lets-form"
                formControlName="industry"
                [ngClass]="{
                  'is-invalid':
                    PartnerFormsubmitted &&
                    AlliancesPartnershipsf['industry'].errors
                }"
              >
                <option value="" disabled selected hidden>Industry</option>
                <option value="AI Technology">AI Technology</option>
                <option value="Insurance">Insurance</option>
                <option value="Accounting & Bookkeeping">
                  Accounting & Bookkeeping
                </option>
                <option value="Others">Others</option>
              </select>
              @if (PartnerFormsubmitted &&
              AlliancesPartnershipsf['industry'].errors) {
              <div class="invalid-feedback">
                @if (
                AlliancesPartnershipsf['industry'].errors['requiredButNotAllowBlank']
                ) {
                <div>*This Field is Required</div>
                }
              </div>
              }
            </div>
            @if (AlliancesPartnerships.get('industry')!.value === "Others"){
            <div class="mt-3">
              <input
                type="text"
                class="lets-form"
                formControlName="partnerProgram"
                placeholder="Mention your industry"
                [ngClass]="{
                  'is-invalid':
                    PartnerFormsubmitted &&
                    AlliancesPartnershipsf['partnerProgram'].errors
                }"
              />
              @if ( PartnerFormsubmitted &&
              AlliancesPartnershipsf['partnerProgram'].errors ) {
              <div class="invalid-feedback">
                @if (
                AlliancesPartnershipsf['partnerProgram'].errors['requiredButNotAllowBlank']
                ) {
                <div>*This Field is Required</div>
                }
              </div>
              }
            </div>
            }
          </div>
          <div class="mt-3">
            <input
              type="text"
              class="lets-form"
              formControlName="motivationToPartner"
              placeholder="What motivates you to become a partner?"
              [ngClass]="{
                'is-invalid':
                  PartnerFormsubmitted &&
                  AlliancesPartnershipsf['motivationToPartner'].errors
              }"
            />
            @if ( PartnerFormsubmitted &&
            AlliancesPartnershipsf['motivationToPartner'].errors ) {
            <div class="invalid-feedback">
              @if (
              AlliancesPartnershipsf['motivationToPartner'].errors['requiredButNotAllowBlank']
              ) {
              <div>*This Field is Required</div>
              }
            </div>
            }
          </div>
          <div class="col-md-6 mt-3">
            <button class="FormCTA">
              <h6 class="font-20 text-primary text-md-start">
                Submit your query
                <div class="headingCTA">
                  <div class="blueCTA podcast-card-cta"></div>
                </div>
              </h6>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  } @if (activeSlide === 'Careers') {
  <div class="row">
    <div
      class="col-lg-4 d-flex align-items-center justify-content-center py-3 d-none d-lg-block"
    >
      <div class="box">
        <img appCdnUrl="assets/contact-us/Careers.jpg" class="img-fluid" />
      </div>
    </div>
    <div class="col-12 col-lg-8 py-3">
      <p class="font-20">
        Let our HR team know what drives your career's passion. Apply today to
        see your career in a whole new light. Be unconventional, be You!
      </p>
      <form
        autocomplete="off"
        [formGroup]="career"
        (ngSubmit)="validateForm(career)"
      >
        <div class="row">
          <div class="col-md-6">
            <div class="mt-3">
              <input
                type="text"
                class="lets-form"
                placeholder="Name"
                appNoNumbers
                formControlName="name"
                [ngClass]="{
                  'is-invalid': submitted && careerf['name'].errors
                }"
              />
              @if (submitted && careerf['name'].errors) {
              <div class="invalid-feedback">
                @if (careerf['name'].errors['requiredButNotAllowBlank']) {
                <div>*This Field is Required</div>
                }
              </div>
              }
            </div>
            <div class="mt-3">
              <input
                type="text"
                class="lets-form"
                formControlName="phone"
                placeholder="Phone"
                appOnlyNumbers
                required
                [ngClass]="{
                  'is-invalid': submitted && careerf['phone'].errors
                }"
              />
              @if (submitted && careerf['phone'].errors) {
              <div class="invalid-feedback">
                @if (careerf['phone'].errors['requiredButNotAllowBlank']) {
                <div>*This Field is Required</div>
                } @if ( !careerf['phone'].errors['requiredButNotAllowBlank'] &&
                careerf['phone'].errors['exactLength'] ) {
                <div>Invalid Phone</div>
                }
              </div>
              }
            </div>
            <div class="mt-3">
              <select
                class="form-select lets-form"
                formControlName="whereDidYouGotToKnow"
                [ngClass]="{
                  'is-invalid':
                    submitted && careerf['whereDidYouGotToKnow'].errors
                }"
              >
                <option value="" disabled selected hidden>
                  Where did you got to know
                </option>
                <option value="News">News</option>
                <option value="Ads">Ads</option>
                <option value="Social Media">Social Media</option>
              </select>
              @if (submitted && careerf['whereDidYouGotToKnow'].errors) {
              <div class="invalid-feedback">
                @if ( careerf['whereDidYouGotToKnow'].errors[
                'requiredButNotAllowBlank' ] ) {
                <div>*This Field is Required</div>
                }
              </div>
              }
            </div>
          </div>
          <div class="col-md-6">
            <div class="mt-3">
              <input
                type="email"
                class="lets-form"
                formControlName="email"
                placeholder="Email address"
                [ngClass]="{
                  'is-invalid': submitted && careerf['email'].errors
                }"
              />
              @if (submitted && careerf['email'].errors) {
              <div class="invalid-feedback">
                @if (careerf['email'].errors['requiredButNotAllowBlank']) {
                <div>*This Field is Required</div>
                } @if (careerf['email'].errors['email']) {
                <div>Invalid email address</div>
                }
              </div>
              }
            </div>
            <div class="mt-3">
              <input
                type="text"
                class="lets-form"
                formControlName="whereUDoFindUs"
                placeholder="Where did you find us"
                [ngClass]="{
                  'is-invalid': submitted && careerf['whereUDoFindUs'].errors
                }"
              />
              @if (submitted && careerf['whereUDoFindUs'].errors) {
              <div class="invalid-feedback">
                @if (
                careerf['whereUDoFindUs'].errors['requiredButNotAllowBlank'] ) {
                <div>*This Field is Required</div>
                }
              </div>
              }
            </div>
            <div class="mt-3">
              <div class="position-relative">
                <input
                  type="file"
                  id="resume-upload"
                  class="d-none"
                  (change)="onResumeFileSelected($event)"
                  formControlName="resume"
                />
                <label
                  for="resume-upload"
                  class="cursor-pointer lets-form"
                  formControlName="resume"
                  [ngClass]="{
                    'is-invalid': submitted && careerf['resume'].errors
                  }"
                >
                  <span>{{ ResumefileName || "Upload Resume" }}</span>
                  <img
                    appCdnUrl="assets/Icon/upload-svg.svg"
                    alt="Upload Icon"
                    class="upload-icon"
                  />
                </label>
                @if (submitted && careerf['resume'].errors) {
                <div class="invalid-feedback">
                  @if (careerf['resume'].errors['requiredButNotAllowBlank']) {
                  <div>
                    *Invalid file format. Only PDF, DOC, and DOCX files are
                    allowed.
                  </div>
                  }
                </div>
                }
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-3">
            <button class="FormCTA">
              <h6 class="font-20 text-primary text-md-start">
                Apply now
                <div class="headingCTA">
                  <div class="blueCTA podcast-card-cta"></div>
                </div>
              </h6>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  } @if (activeSlide === 'Media & General Query') {
  <div class="row">
    <div
      class="col-lg-4 d-flex align-items-center justify-content-center py-3 d-none d-lg-block"
    >
      <div class="box">
        <img appCdnUrl="assets/contact-us/MediaQuery.jpg" class="img-fluid" />
      </div>
    </div>
    <div class="col-12 col-lg-8 py-3">
      <p class="font-20">
        In case you want us to know about anything else, please feel free to
        email your queries at
        <a href="mailto:support@fbspl.com">support&#64;fbspl.com</a>
      </p>
      <form
        autocomplete="off"
        [formGroup]="MediaGeneralQuery"
        (ngSubmit)="validateForm(MediaGeneralQuery)"
      >
        <div class="row">
          <div class="col-md-6">
            <div class="mt-3">
              <input
                type="text"
                class="lets-form"
                placeholder="Name"
                appNoNumbers
                formControlName="name"
                [ngClass]="{
                  'is-invalid': submitted && MediaGeneralQueryf['name'].errors
                }"
              />
              @if (submitted && MediaGeneralQueryf['name'].errors) {
              <div class="invalid-feedback">
                @if ( MediaGeneralQueryf['name'].errors[
                'requiredButNotAllowBlank' ] ) {
                <div>*This Field is Required</div>
                }
              </div>
              }
            </div>
            <div class="mt-3 phone-input-container">
              <select
                class="lets-form country-select form-select"
                (change)="onCountryChange($event)"
                formControlName="countrycode"
              >
                @for (country of countryList; track country) {
                <option [value]="country.code" [attr.data-code]="country.code">
                  {{ country.name }} ({{ country.code }})
                </option>
                }
              </select>
              <input
                type="text"
                class="lets-form phone-number-input phn_padding"
                formControlName="phone"
                placeholder="Phone"
                appOnlyNumbers
                required
                [ngClass]="{
                  'is-invalid': submitted && MediaGeneralQueryf['phone'].errors
                }"
              />
              @if (submitted && MediaGeneralQueryf['phone'].errors) {
              <div class="invalid-feedback">
                @if ( MediaGeneralQueryf['phone'].errors[ 'required' ] ) {
                <div>*This Field is Required</div>
                } @if ( !MediaGeneralQueryf['phone'].errors[ 'required' ] &&
                MediaGeneralQueryf['phone'].errors['exactLength'] ) {
                <div>Invalid Phone</div>
                }
              </div>
              }
            </div>
            <div class="mt-3">
              <input
                type="text"
                class="lets-form"
                formControlName="whereUFindUs"
                placeholder="Where did you find us"
                [ngClass]="{
                  'is-invalid':
                    submitted && MediaGeneralQueryf['whereUFindUs'].errors
                }"
              />
              @if (submitted && MediaGeneralQueryf['whereUFindUs'].errors) {
              <div class="invalid-feedback">
                @if ( MediaGeneralQueryf['whereUFindUs'].errors[
                'requiredButNotAllowBlank' ] ) {
                <div>*This Field is Required</div>
                }
              </div>
              }
            </div>
          </div>
          <div class="col-md-6">
            <div class="mt-3">
              <input
                type="email"
                class="lets-form"
                formControlName="email"
                placeholder="Email address"
                [ngClass]="{
                  'is-invalid': submitted && MediaGeneralQueryf['email'].errors
                }"
              />
              @if (submitted && MediaGeneralQueryf['email'].errors) {
              <div class="invalid-feedback">
                @if ( MediaGeneralQueryf['email'].errors[
                'requiredButNotAllowBlank' ] ) {
                <div>*This Field is Required</div>
                } @if (MediaGeneralQueryf['email'].errors['email']) {
                <div>Invalid email address</div>
                }
              </div>
              }
            </div>
            <div class="mt-3">
              <textarea
                class="lets-form"
                rows="4"
                placeholder="Write a message"
                formControlName="message"
                [ngClass]="{
                  'is-invalid':
                    submitted && MediaGeneralQueryf['message'].errors
                }"
              ></textarea>
              @if (submitted && MediaGeneralQueryf['message'].errors) {
              <div class="invalid-feedback">
                @if ( MediaGeneralQueryf['message'].errors[
                'requiredButNotAllowBlank' ] ) {
                <div>*This Field is Required</div>
                }
              </div>
              }
            </div>
          </div>
          <div class="col-md-6 mt-3">
            <button class="FormCTA">
              <h6 class="font-20 text-primary text-md-start">
                Submit your query
                <div class="headingCTA">
                  <div class="blueCTA podcast-card-cta"></div>
                </div>
              </h6>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  }
</section>

<section class="container py-6" data-aos="zoom-in">
  <h3 class="text-primary font-48-30 mb-4 pt-xl-5">
    Reach out to us, follow and more
  </h3>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-3 my-3">
      <img
        appCdnUrl="assets/contact-us/big-location.svg"
        class="img-fluid icons"
      />
      <h3 class="font-28-20 text-primary my-3">Address - INDIA</h3>
      <img appCdnUrl="assets/Icon/dotted-border.svg" class="mb-3" />
      <h3 class="font-24-16 fw-bold mb-3">
        Fusion Business Solutions (P) Limited
      </h3>
      <p class="font-20-16 fw-normal text-light">
        F-37, IT Park, MIA Extension <br />
        Udaipur, Rajasthan, India<br />
        313002<br />
        <a href="tel:+91-977-227-9996"> +91-977-227-9996</a>
      </p>
      <p class="font-20-16 fw-normal text-light">
        Email: <a href="mailto:support@fbspl.com">support&#64;fbspl.com</a>
      </p>
    </div>
    <div class="col-12 col-md-6 col-lg-3 my-3">
      <img
        appCdnUrl="assets/contact-us/big-location.svg"
        class="img-fluid icons"
      />
      <h3 class="font-28-20 text-primary my-3">Address - USA</h3>
      <img appCdnUrl="assets/Icon/dotted-border.svg" class="mb-3" />
      <h3 class="font-24-16 fw-bold mb-3">
        Fusion Global Business Solution INC.
      </h3>
      <p class="font-20-16 fw-normal text-light">
        459 COLUMBUS AVE #4018<br />
        NEW YORK, NY<br />
        10024<br />
        <a href="tel:+1-240-979-0061">+1-240-979-0061</a><br />
      </p>
      <p class="font-20-16 fw-normal text-light">
        Email: <a href="mailto:support@fbspl.com">support&#64;fbspl.com</a>
      </p>
    </div>
    <div class="col-12 col-md-6 col-lg-3 my-3">
      <img
        appCdnUrl="assets/contact-us/phone-call.svg"
        class="img-fluid icons"
      />
      <h3 class="font-28-20 text-primary my-3">Phone numbers</h3>
      <img appCdnUrl="assets/Icon/dotted-border.svg" class="mb-3" />
      <p class="font-20-16 fw-normal text-light">
        <span class="fw-700">UNITED STATES</span> -
        <a href="tel:+1-240-979-0061">+1-240-979-0061</a><br />
        <!-- <span class="fw-700">UNITED KINGDOM</span> -
        <a href="tel:+44-788-331-8386">+44-788-331-8386</a><br /> -->
        <span class="fw-700">CANADA</span> -
        <a href="tel:+1-240-979-0061">+1-240-979-0061</a><br />
      </p>
      <p class="font-20-16 fw-normal text-light">
        Email: <a href="mailto:support@fbspl.com">support&#64;fbspl.com</a>
      </p>
    </div>
    <div class="col-12 col-md-6 col-lg-3 my-3">
      <img appCdnUrl="assets/contact-us/message.svg" class="img-fluid icons" />
      <h3 class="font-28-20 text-primary my-3">For any escalation</h3>
      <img appCdnUrl="assets/Icon/dotted-border.svg" class="mb-3" />
      <p class="font-20-16 fw-normal text-light">
        <span class="fw-700">For job seekers</span><br />
        <a href="tel:+91-9602254863">Mo. +91-9602254863</a>,<br />
        <a href="tel:+91-8619805918">+91-8619805918</a><br />
        <span class="font-20-16 fw-normal text-light"
          >Email: <a href="mailto:hr@fbspl.com">hr&#64;fbspl.com</a></span
        >
      </p>
      <p class="font-20-16 fw-normal text-light">
        <span class="fw-700">For media-related queries</span><br />
        Mo. <a href="tel:+91-9166118997">+91-9166118997</a><br />
        <span class="font-20-16 fw-normal text-light"
          >Email:
          <a href="mailto:marketing@fbspl.com">marketing&#64;fbspl.com</a></span
        >
      </p>
    </div>
    <div class="col-12 col-md-6 col-lg-3 my-3">
      <img
        appCdnUrl="assets/contact-us/social-icon.svg"
        class="img-fluid icons"
      />
      <h3 class="font-28-20 text-primary my-3">Follow us</h3>
      <img appCdnUrl="assets/Icon/dotted-border.svg" class="mb-3" />
      <div class="d-flex flex-wrap">
        <div>
          <a
            href="https://www.facebook.com/FBSPLTeam"
            target="_blank"
            title="Facebook"
            rel="nofollow"
            class="footerLink"
            ><img
              class="logo upTranslate me-3"
              appCdnUrl="assets/Icon/facebook.svg"
              alt="facebook"
            />
          </a>
        </div>
        <div>
          <a
            href="https://twitter.com/FBSPLTeam"
            target="_blank"
            title="Twitter"
            class="footerLink"
            ><img
              class="logo upTranslate me-3"
              appCdnUrl="assets/Icon/twitter.svg"
              alt="twitter"
            />
          </a>
        </div>
        <div>
          <a
            href="https://www.youtube.com/@FBSPLTeam"
            target="_blank"
            title="Youtube"
            rel="nofollow"
            class="footerLink"
            ><img
              class="logo upTranslate me-3"
              appCdnUrl="assets/Icon/youtube.svg"
              alt="youtube"
            />
          </a>
        </div>
        <div>
          <a
            href="https://www.instagram.com/fbsplteam/"
            target="_blank"
            title="Instagram"
            rel="nofollow"
            class="footerLink"
            ><img
              class="logo upTranslate me-3"
              appCdnUrl="assets/Icon/instagram.svg"
              alt="instagram"
            />
          </a>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/company/fbsplteam"
            target="_blank"
            title="LinkedIn"
            rel="nofollow"
            class="footerLink"
            ><img
              class="logo upTranslate me-3"
              appCdnUrl="assets/Icon/linkedin.svg"
              alt="linkedin"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="bg-secondary-30 py-4">
  <div class="container">
    <div class="row justify-content-center">
      <div class="row text-center">
        <div class="col-12 col-md-4">
          <div class="text-start">
            <p class="fw-normal font-96-30 mb-0">6</p>
            <p class="fw-normal font-36-24">Countries</p>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="text-start">
            <p class="fw-normal font-96-30 mb-0">550+</p>
            <p class="fw-normal font-36-24">Satisfied clients</p>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="text-start">
            <p class="fw-normal font-96-30 mb-0">1050+</p>
            <p class="fw-normal font-36-24">Exceptionally talented resources</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="container py-3">
  <div class="map-container">
    <div id="tooltip" class="tooltip1 p-2">
      <h2 id="tooltiph2" class="font-28-20"></h2>
      <p id="tooltipP" class="font-20-16 mb-0"></p>
    </div>
    <div id="svg-container"></div>
  </div>
</section>
