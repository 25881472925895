<!-- /*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/ -->
<app-header></app-header>
<!-- <app-loader></app-loader> -->
<main>
  <router-outlet></router-outlet>
</main>
<app-footer></app-footer>