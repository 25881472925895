<!-- /*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/ -->
<section class="container pb-5 position-relative mb-4">
  <img
    appCdnUrl="assets/Icon/blue-square.svg"
    class="center-right-float vector z-3"
  />
  <img
    appCdnUrl="assets/Icon/snake-vector.svg"
    class="left-bottom-float vector"
  />
  <div class="error-section">
    <div id="Animationthankyou"></div>
    <h1 class="font-128-64 text-primary text-center">THANK YOU</h1>
    <h2 class="font-24-16 text-center fw-normal mb-5 mt-4">
      We are excited about your interest in our services!<br />
      Our experts will get in touch with you at your preferred time and date.
    </h2>
    <a
      href="https://meetings.hubspot.com/fbspl-sales-team/fbspl-growth-team"
      class="anchorCTA mt-4"
    >
      <h6 class="font-48-30 text-primary text-center">
        Schedule Your Call
        <div class="headingCTA">
          <button class="blueCTA podcast-card-cta"></button>
        </div>
      </h6>
    </a>
  </div>
</section>