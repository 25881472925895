/*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/
import { Component } from '@angular/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@Component({
  selector: 'app-Slider',
  standalone: true,
  imports: [SlickCarouselModule],
  templateUrl: './Slider.component.html',
  styleUrl: './Slider.component.css',
})
export class SliderComponent {
  slides = [
    {
      img: 'https://d2x053sc4cmboc.cloudfront.net/LP/Accounting&Bookkeeping/bookACall.svg',
      headingContent: 'AI + human = growth',
      parra: 'A powerful blend of AI and human expertise for real results.',
      step: 1,
    },
    {
      img: 'https://d2x053sc4cmboc.cloudfront.net/LP/Accounting&Bookkeeping/fillTheForm.svg',
      headingContent: 'Your business, your way',
      parra:
        'Services that fit your business, with teams aligning to your time-zone.',
      step: 2,
    },
    {
      img: 'https://d2x053sc4cmboc.cloudfront.net/LP/Accounting&Bookkeeping/Domain.svg',
      headingContent: ' All-in-one support',
      parra:
        'Complete support with dedicated resources and back-up staff.',
      step: 3,
    },
    {
      img: 'https://d2x053sc4cmboc.cloudfront.net/LP/Accounting&Bookkeeping/scopeOfWork.svg',
      headingContent: 'Client first, always',
      parra: "We're not just a service provider; we're your partner in growth. ",
      step: 4,
    },
  ];

  slickConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    dots: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1, // Adjust for smaller screens
          dots: true,
          prevArrow: false,
          nextArrow: false,
        },
      },
    ],
  };

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    arrow: false,
  };
  constructor() {}

  ngOnInit(): void {}
}
