/*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/
import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { RouterLink, ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { SeoService } from '../../services/seo/seo.service';
import { SeoData } from '../../services/seo/seo-data.model';
import { JdService } from '../../services/jd/jd.service';
import { JdGlobalDataService } from '../../services/JdGlobalData/JdGlobalDataService.service';
import { CdnUrlDirective } from '../../directives/cdn-url.directive';

@Component({
  selector: 'app-jd',
  standalone: true,
  imports: [RouterLink, CdnUrlDirective],
  templateUrl: './jd.component.html',
  styleUrl: './jd.component.css',
})
export class JdComponent implements OnInit {
  jdData: any = {};
  positionName = '';
  constructor(
    private seoService: SeoService,
    private route: ActivatedRoute,
    private Jdservice: JdService,
    private router: Router,
    private dataService: JdGlobalDataService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        this.loadJd(fragment);
      } else {
        this.router.navigate(['/careers']);
      }
    });
  }

  loadJd(jobRole: string): void {
    this.Jdservice.getJdData(jobRole).subscribe(
      (data) => {
        this.jdData = data.data;
        this.positionName = this.jdData.position_name;
        const departmentName = this.jdData.department_name;
        const jdLink = this.jdData.jd_data;
        const seoData: SeoData = {
          title: `${this.positionName} at FBSPL - Join Our Team`,
          description: `Apply now for the ${this.positionName} position at FBSPL. We're Great Place to Work-certified and offer great opportunities for growth and development. Apply today!`,
          keywords: 'FBSPL, Great Place to Work, job opening',
        };
        this.seoService.setMetaTags(seoData);
        this.dataService.setData(this.positionName, departmentName, jdLink);
      },
      (error) => {
        this.router.navigate(['/careers']);
        console.error('Error fetching jd', error);
      }
    );
  }

  hasApiData(): boolean {
    return this.jdData && Object.keys(this.jdData).length > 0;
  }
}
