<!-- /*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/ -->
<form
  autocomplete="off"
  [formGroup]="consultationForm"
  (ngSubmit)="validateForm(consultationForm)"
  class="text-start"
>
  <div class="row">
    <div class="col-md-6 mt-3">
      <input
        type="text"
        class="lets-form"
        formControlName="firstname"
        appNoNumbers
        autocomplete="off"
        placeholder="Name"
        [ngClass]="{
          'is-invalid': submitted && consultationFormf['firstname'].errors
        }"
      />
      @if (submitted && consultationFormf['firstname'].errors) {
      <div class="invalid-feedback">
        @if ( consultationFormf['firstname'].errors['requiredButNotAllowBlank']
        ) {
        <div>*This Field is Required</div>
        }
      </div>
      }
    </div>
    <div class="col-md-6 mt-3">
      <input
        type="email"
        class="lets-form"
        formControlName="email"
        placeholder="Email Address"
        [ngClass]="{
          'is-invalid': submitted && consultationFormf['email'].errors
        }"
      />
      @if (submitted && consultationFormf['email'].errors) {
      <div class="invalid-feedback">
        @if ( consultationFormf['email'].errors['requiredButNotAllowBlank'] ) {
        <div>*This Field is Required</div>
        } @if (consultationFormf['email'].errors['email']) {
        <div>Invalid email address</div>
        }
      </div>
      }
    </div>
    <div class="col-md-6 mt-3">
      <input
        type="text"
        class="lets-form"
        formControlName="company"
        autocomplete="off"
        placeholder="Company Name"
        [ngClass]="{
          'is-invalid': submitted && consultationFormf['company'].errors
        }"
      />
      @if (submitted && consultationFormf['company'].errors) {
      <div class="invalid-feedback">
        @if ( consultationFormf['company'].errors['requiredButNotAllowBlank'] )
        {
        <div>*This Field is Required</div>
        }
      </div>
      }
    </div>
    <div class="col-md-6 mt-3">
      <select
        class="form-select lets-form"
        formControlName="services"
        [ngClass]="{
          'is-invalid': submitted && consultationFormf['services'].errors
        }"
      >
        <option value="" selected hidden>Services</option>
        <option value="insurance">Insurance</option>
        <option value="accounting bookkeeping">Accounting & Bookkeeping</option>
        <option value="insurance agency consultation">
          Insurance Agency Consultation
        </option>
        <option value="data annotation">Data Annotation</option>
        <option value="digital marketing">Digital Marketing</option>
        <option value="Business Intelligence">Business Intelligence</option>
        <option value="Artificial Intelligence">Artificial Intelligence</option>
      </select>
      @if (submitted && consultationFormf['services'].errors) {
      <div class="invalid-feedback">
        @if ( consultationFormf['services'].errors['requiredButNotAllowBlank'] )
        {
        <div>*This Field is Required</div>
        }
      </div>
      }
    </div>
    <div class="col-md-4 mt-3">
      <input
        type="text"
        class="lets-form"
        formControlName="phone"
        autocomplete="off"
        placeholder="Phone"
        appOnlyNumbers
        required
        [ngClass]="{
          'is-invalid': submitted && consultationFormf['phone'].errors
        }"
      />
      @if (submitted && consultationFormf['phone'].errors) {
      <div class="invalid-feedback">
        @if ( consultationFormf['phone'].errors['requiredButNotAllowBlank'] ) {
        <div>*This Field is Required</div>
        }
      </div>
      }
    </div>
    <div class="col-md-4 mt-3">
      <input
        type="text"
        class="lets-form"
        formControlName="country"
        autocomplete="off"
        placeholder="Country"
        appNoNumbers
        [ngClass]="{
          'is-invalid': submitted && consultationFormf['country'].errors
        }"
      />
      @if (submitted && consultationFormf['country'].errors) {
      <div class="invalid-feedback">
        @if ( consultationFormf['country'].errors['requiredButNotAllowBlank'] )
        {
        <div>*This Field is Required</div>
        }
      </div>
      }
    </div>
    <div class="col-md-4 mt-3">
      <input
        type="text"
        class="lets-form"
        formControlName="state"
        autocomplete="off"
        placeholder="State"
        appNoNumbers
        [ngClass]="{
          'is-invalid': submitted && consultationFormf['state'].errors
        }"
      />
      @if (submitted && consultationFormf['state'].errors) {
      <div class="invalid-feedback">
        @if ( consultationFormf['state'].errors['requiredButNotAllowBlank'] ) {
        <div>*This Field is Required</div>
        }
      </div>
      }
    </div>
    <div class="col-md-12 p-2 mt-3">
      <textarea
        class="lets-form"
        formControlName="write_your_message"
        placeholder="Brief Requirement"
        [ngClass]="{
          'is-invalid':
            submitted && consultationFormf['write_your_message'].errors
        }"
        rows="4"
      ></textarea>

      @if (submitted && consultationFormf['write_your_message'].errors) {
      <div class="invalid-feedback">
        @if ( consultationFormf['write_your_message'].errors[
        'requiredButNotAllowBlank' ] ) {
        <div>*This Field is Required</div>
        }
      </div>
      }
    </div>
    <div class="col-md-12 p-2">
      <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          formControlName="accept_policy"
          id="acceptPolicy"
          [ngClass]="{
            'is-invalid': submitted && consultationFormf['accept_policy'].errors
          }"
        />

        <label class="form-check-label" for="acceptPolicy">
          By opting in for text messages, you agree to receive messages from
          Fusion Global Business Solution Inc at the number provided. Message
          frequency varies. Msg & data rates may apply. Reply STOP to
          unsubscribe. Reply HELP for help. View our
          <a href="/privacy-policy" class="text-primary">Privacy Policy</a> and
          <a href="/terms-&-condition" class="text-primary"
            >Terms & Conditions</a
          >
          for more information.
        </label>

        @if (submitted && consultationFormf['accept_policy'].errors) {
        <div class="invalid-feedback">
          @if (consultationFormf['accept_policy'].errors['required']) {
          <div>*You must accept the terms to proceed</div>
          }
        </div>
        }
      </div>
    </div>

    <div class="col-md-12 mt-3 p-2">
      <SubmitBtn text="Submit now"></SubmitBtn>
    </div>
  </div>
</form>
