/*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/
import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import {
  provideHttpClient,
  withInterceptors,
  withFetch,
} from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import { provideServiceWorker } from '@angular/service-worker';

import { routes } from './app.routes';
import { loaderInterceptor } from './interceptors/Loader.interceptor';
import { LoaderService } from './services/loader/loader.service';
import { IMAGE_CONFIG } from '@angular/common';

// export const appConfig: ApplicationConfig = {
//   providers: [
//     {
//       provide: IMAGE_CONFIG,
//       useValue: {
//         disableImageSizeWarning: true,
//         disableImageLazyLoadWarning: true,
//       },
//     },
//     provideHttpClient(withInterceptors([]), withFetch()),
//     provideRouter(
//       routes,
//       withInMemoryScrolling({
//         scrollPositionRestoration: 'enabled',
//         anchorScrolling: 'enabled',
//       })
//     ),
//     provideClientHydration(),
//   
//     LoaderService,
//   ],

// };
export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptors([]), withFetch()),
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      })
    ),
    
  ],
};
