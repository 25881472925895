/*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/
import {
  Component,
  OnInit,
  Renderer2,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, Router } from '@angular/router';
import { SeoService } from '../../services/seo/seo.service';
import { SeoData } from '../../services/seo/seo-data.model';
import { OnlyNumbersDirective } from '../../../../src/app/directives/onlyNumbers.directive';
import { NoNumbersDirective } from '../../directives/noNumbers.directive';
import { EmailService } from '../../services/bookAConsultation_Email/bookAConsultation.component';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { HubspotService } from '../../services/hubspot/hubspot.service';
import {
  requiredButNotAllowBlank,
  customEmailValidator,
} from '../../utils/Validator';
import { CdnUrlDirective } from '../../directives/cdn-url.directive';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { MainForm } from '../../components/MainForm/MainForm.component';

@Component({
  selector: 'app-bookAConsultation',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    CdnUrlDirective,
    NoNumbersDirective,
    OnlyNumbersDirective,
    MainForm
  ],
  templateUrl: './bookAConsultation.component.html',
  styleUrl: './bookAConsultation.component.css',
})
export class BookAConsultationComponent implements OnInit {
  consultationForm!: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private seoService: SeoService,
    private router: Router,
    private hubspotService: HubspotService,
    private emailService: EmailService, // Inject EmailService
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit(): void {
    // this.consultationForm = this.formBuilder.group({
    //   firstname: ['', requiredButNotAllowBlank()],
    //   email: ['', [requiredButNotAllowBlank(), customEmailValidator]],
    //   company: ['', requiredButNotAllowBlank()],
    //   services: ['', requiredButNotAllowBlank()],
    //   phone: [''],
    //   country: ['', requiredButNotAllowBlank()],
    //   state: ['', requiredButNotAllowBlank()],
    //   write_your_message: ['', requiredButNotAllowBlank()],
    //   accept_policy: [false, Validators.requiredTrue],
    // });
    const seoData: SeoData = {
      title: 'Book Consultation | Schedule Your Appointment Today - FBSPL',
      description:
        'Schedule your free consultation to learn how FBSPL can elevate your business with expert process management consultation and BPO services.',
      keywords: 'book consultation',
      ogImage: '/BookAConsultation/BookConsultation.jpg',
    };
    this.addJsonLdScript();
    this.seoService.setMetaTags(seoData);
  }
  // get consultationFormf() {
  //   return this.consultationForm.controls;
  // }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
      // Check and add BreadcrumbList script
      if (
        !this.document.head.querySelector(
          'script[type="application/ld+json"][data-script="breadcrumb"]'
        )
      ) {
        const breadcrumbScript = this.renderer.createElement('script');
        breadcrumbScript.type = 'application/ld+json';
        breadcrumbScript.setAttribute('data-script', 'breadcrumb');
        breadcrumbScript.text = `
    { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Book Free Consultation", 

      "item": "https://www.fbspl.com/book-free-consultation-call" 

    } 

  ] 

} `;
        this.renderer.appendChild(this.document.head, breadcrumbScript);
      }
    }
  }
  // validateForm(form: FormGroup): void {
  //   this.submitted = true;
  //   if (this.consultationForm.invalid) {
  //     return;
  //   }
  //   const formId = '54cdb521-e6b8-423b-b30b-33438b3ad5ae';
  //   const formData = this.consultationForm.value;
  //   this.hubspotService.submitForm(formId, formData).subscribe(
  //     (response) => {
  //       console.log('Form submitted successfully:', response);
  //       this.router.navigate(['/book-free-consultation-call/thankyou']);
  //       // Send an email after successful submission
  //       this.emailService.sendEmail(formData).subscribe(
  //         (emailResponse) => {
  //           console.log('Email sent successfully:', emailResponse);
  //         },
  //         (error) => {
  //           console.error('Error sending email:', error);
  //         }
  //       );
  //     },
  //     (error) => {
  //       console.error('Error submitting form:', error);
  //       alert('Error submitting form. Please try again.');
  //     }
  //   );
  // }
}
