<!-- /*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/ -->
<div class="container d-none d-lg-block">
  <div class="gradientBanner">
    <img
      appCdnUrl="assets/leaders/Banner/LeadershipBanner.png"
      class="desktop-banner"
    />
    <div class="bannerText" data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item">
            <a href="./about-who-we-are">About FBSPL</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Our leadership
          </li>
        </ol>
      </nav>
      <h1 class="font-48-30 text-primary pt-3">Our leadership</h1>
      <p class="card-text font-20-16">
        They believe in the power of thinking implementation,<br />
        and excellence.
      </p>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark d-block d-lg-none pt-5">
  <div class="gradientBannerMob">
    <img
      appCdnUrl="assets/leaders/Banner/MobileLeadershipBanner.png"
      class="card-img-mob"
    />
    <div class="bannerTextMob" data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item">
            <a href="./about-who-we-are">About FBSPL</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Our leadership
          </li>
        </ol>
      </nav>
      <h1 class="font-48-30 text-primary">Our leadership</h1>
      <p class="card-text font-20-16">
        They believe in the power of thinking implementation, and excellence.
      </p>
    </div>
  </div>
</div>

<section class="container py-5">
  <h2 class="text-primary font-48-30" data-aos="slide-up">
    Here's to the visionaries who ignited these impacts
  </h2>

  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
      <div class="imageWrapperCard p-3 md-card">
        <div class="imageText">
          <h3 class="font-34-19 text-primary">Madhukar S Dubey</h3>
          <p class="font-20-16 fw-bold mb-2">Founder & Managing Director</p>
          <div>
            <a
              href="https://www.linkedin.com/in/madhukarsdubey/"
              rel="nofollow noopener noreferrer"
              target="blank"
              class="linkedinicon"
            >
              <img appCdnUrl="assets/Icon/linkedin.svg" class="linkedicon"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
      <div class="imageWrapperCard p-3 sh-card">
        <div class="imageText">
          <h3 class="font-34-19 text-primary">Shweta Dubey</h3>
          <p class="font-20-16 fw-bold mb-2">CEO and Co-Founder</p>
          <div>
            <a
              href="https://www.linkedin.com/in/shwetadubey42/"
              rel="nofollow noopener noreferrer"
              target="blank"
              class="linkedinicon"
            >
              <img appCdnUrl="assets/Icon/linkedin.svg" class="linkedicon"
            /></a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
      <div class="imageWrapperCard p-3 vv-card">
        <div class="imageText">
          <h3 class="font-34-19 text-primary">Vinod Verma</h3>
          <p class="font-20-16 fw-bold mb-2">Co-CEO</p>
          <div>
            <a
              href="https://www.linkedin.com/in/vinod-verma-1ba70142/"
              rel="nofollow noopener noreferrer"
              target="blank"
              class="linkedinicon"
            >
              <img appCdnUrl="assets/Icon/linkedin.svg" class="linkedicon"
            /></a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
      <div class="imageWrapperCard p-3 kb-card">
        <div class="imageText">
          <h3 class="font-34-19 text-primary">Kuldeep Bhatnagar (KB)</h3>
          <p class="font-20-16 fw-bold mb-2">Chief of Sales & Marketing</p>
          <div>
            <a
              href="https://www.linkedin.com/in/bhatnagarkuldeep/"
              rel="nofollow noopener noreferrer"
              target="blank"
              class="linkedinicon"
            >
              <img appCdnUrl="assets/Icon/linkedin.svg" class="linkedicon"
            /></a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
      <div class="imageWrapperCard p-3 ss-card">
        <div class="imageText">
          <h3 class="font-34-19 text-primary">Shakti Singh Shekhawat</h3>
          <p class="font-20-16 fw-bold mb-2">
            Assistant Vice President,<br />
            Strategic Planning
          </p>
          <div>
            <a
              href="https://www.linkedin.com/in/shakti-singh-shekhawat-43912920/"
              rel="nofollow noopener noreferrer"
              target="blank"
              class="linkedinicon"
            >
              <img appCdnUrl="assets/Icon/linkedin.svg" class="linkedicon"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
      <div class="imageWrapperCard p-3 laura-card">
        <div class="imageText">
          <h3 class="font-34-19 text-primary">Laura Waldyn</h3>
          <p class="font-20-16 fw-bold mb-2">
            Vice President,<br />
            Agency Optimization<br />
            Services
          </p>
          <div>
            <a
              href="https://www.linkedin.com/in/laura-waldyn-0247ab9/"
              rel="nofollow noopener noreferrer"
              target="blank"
              class="linkedinicon"
            >
              <img appCdnUrl="assets/Icon/linkedin.svg" class="linkedicon"
            /></a>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="col-sm-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
      <div class="imageWrapperCard p-3 tracy-card">
        <div class="imageText">
          <h3 class="font-34-19 text-primary">Tracy Klitch</h3>
          <p class="font-20-16 fw-bold mb-2">Vice President, Sales</p>
          <div>
            <a
              href="https://www.linkedin.com/in/tracy-klitch-2598278b/"
              rel="nofollow noopener noreferrer"
              target="blank"
              class="linkedinicon"
            >
              <img appCdnUrl="assets/Icon/linkedin.svg" class="linkedicon"
            /></a>
          </div>
        </div>
      </div>
    </div> -->
    <div class="col-sm-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
      <div class="imageWrapperCard p-3 sd-card">
        <div class="imageText">
          <h3 class="font-34-19 text-primary">Shekhar Dhabhai</h3>
          <p class="font-20-16 fw-bold mb-2">
            Assistant General Manager - <br />Operations
          </p>
          <div>
            <a
              href="https://www.linkedin.com/in/shekhar-dhabhai-54288340/"
              rel="nofollow noopener noreferrer"
              target="blank"
              class="linkedinicon"
            >
              <img appCdnUrl="assets/Icon/linkedin.svg" class="linkedicon"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
      <div class="imageWrapperCard p-3 ac-card">
        <div class="imageText">
          <h3 class="font-34-19 text-primary">Ankur Chatterjee</h3>
          <p class="font-20-16 fw-bold mb-2">
            Assistant General Manager -<br />
            Sales & Marketing
          </p>
          <div>
            <a
              href="https://www.linkedin.com/in/fullcyclerecruiting"
              rel="nofollow noopener noreferrer"
              target="blank"
              class="linkedinicon"
            >
              <img appCdnUrl="assets/Icon/linkedin.svg" class="linkedicon"
            /></a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
      <div class="imageWrapperCard p-3 db-card">
        <div class="imageText">
          <h3 class="font-34-19 text-primary">Divya Babel</h3>
          <p class="font-20-16 fw-bold mb-2">
            Assistant General Manager -<br />
            Finance
          </p>
          <div>
            <a
              href="https://www.linkedin.com/in/divya-babel-75539676/"
              rel="nofollow noopener noreferrer"
              target="blank"
              class="linkedinicon"
            >
              <img appCdnUrl="assets/Icon/linkedin.svg" class="linkedicon"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
      <div class="imageWrapperCard p-3 as-card">
        <div class="imageText">
          <h3 class="font-34-19 text-primary">Avinash Shrivastava</h3>
          <p class="font-20-16 fw-bold mb-2">Manager, Information Technology</p>
          <div>
            <a
              href="https://www.linkedin.com/in/avinash-kumar-shrivastava-ab107b42"
              rel="nofollow noopener noreferrer"
              target="blank"
              class="linkedinicon"
            >
              <img appCdnUrl="assets/Icon/linkedin.svg" class="linkedicon"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
      <div class="imageWrapperCard p-3 chetan-card">
        <div class="imageText">
          <h3 class="font-34-19 text-primary">Chetan Sharma</h3>
          <p class="font-20-16 fw-bold mb-2">Head of Process Excellence Domain</p>
          <div>
            <a
              href="https://www.linkedin.com/in/chetan-sharma-a19596110/"
              rel="nofollow noopener noreferrer"
              target="blank"
              class="linkedinicon"
            >
              <img appCdnUrl="assets/Icon/linkedin.svg" class="linkedicon"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
      <div class="imageWrapperCard p-3 bharat-card">
        <div class="imageText">
          <h3 class="font-34-19 text-primary">Bharat Lokchandani</h3>
          <p class="font-20-16 fw-bold mb-2">Head of Insurance Domain</p>
          <div>
            <a
              href="https://www.linkedin.com/in/bharat-lokchandani-53167b68/"
              rel="nofollow noopener noreferrer"
              target="blank"
              class="linkedinicon"
            >
              <img appCdnUrl="assets/Icon/linkedin.svg" class="linkedicon"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-sm-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
      <div class="imageWrapperCard p-3 ag-card">
        <div class="imageText">
          <h3 class="font-34-19 text-primary">Aakash Gabhrani</h3>
          <p class="font-20-16 fw-bold mb-2">
            Head of Ecommerce  <br />
            Domain
          </p>
          <div>
            <a
              href="https://www.linkedin.com/in/aakashgabhrani01/"
              rel="nofollow noopener noreferrer"
              target="blank"
              class="linkedinicon"
            >
              <img appCdnUrl="assets/Icon/linkedin.svg" class="linkedicon"
            /></a>
          </div>
        </div>
      </div>
    </div> -->
    <div class="col-sm-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
      <div class="imageWrapperCard p-3 fo-card">
        <div class="imageText">
          <h3 class="font-34-19 text-primary">Fakhruddin Abbas Ali Zakir</h3>
          <p class="font-20-16 fw-bold mb-2">Head of Process Domain</p>
          <div>
            <a
              href="https://www.linkedin.com/in/fakhruddin-abbas-ali-zakir-21605aa8/"
              rel="nofollow noopener noreferrer"
              target="blank"
              class="linkedinicon"
            >
              <img appCdnUrl="assets/Icon/linkedin.svg" class="linkedicon"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
      <div class="imageWrapperCard p-3 tarun-card">
        <div class="imageText">
          <h3 class="font-34-19 text-primary">Tarun Singh Rathore</h3>
          <p class="font-20-16 fw-bold mb-2">Head of Recruitment Domain</p>
          <div>
            <a
              href="https://www.linkedin.com/in/tarun-singh-rathore-88b98129/"
              rel="nofollow noopener noreferrer"
              target="blank"
              class="linkedinicon"
            >
              <img appCdnUrl="assets/Icon/linkedin.svg" class="linkedicon"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
