<!-- /*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/ -->
<div class="container d-none d-lg-block">
  <div class="gradientBanner">
    <img
      appCdnUrl="assets/Security-Measures/SecurityMeasuresBanner.jpg"
      class="desktop-banner"
      alt="Security Measures at FBSPL "
    />
    <div class="bannerText" data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Security Measures
          </li>
        </ol>
      </nav>
      <h1 class="font-48-30 text-primary fw-500 pt-3">Security Measures</h1>
    </div>
  </div>
</div>
<div class="container text-dark slide d-block d-lg-none pt-5">
  <div class="gradientBannerMob">
    <img
      appCdnUrl="assets/Security-Measures/SecurityMeasuresMobileBanner.jpg"
      class="card-img-mob" alt="Security Measures at FBSPL "
    />
    <div class="bannerTextMob" data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Security Measures
          </li>
        </ol>
      </nav>
      <h1 class="font-48-30 text-primary fw-500 pt-3">Security Measures</h1>
    </div>
  </div>
</div>

<section class="container py-6">
  <p class="font-24-16 text-light fw-normal">
    When it comes to cybersecurity, we follow the three golden rules:
    confidentiality, Integrity, and Availability, collectively known as the CIA
    triad.
  </p>
  <p class="font-24-16 text-light fw-normal">
    These principles are the backbone of our security approach, ensuring that
    our client's data is always safe and secure.
  </p>
  <p class="font-24-16 text-light fw-normal">
    To uphold the CIA triad, we've implemented a range of controls across
    technical, operational, and managerial aspects to safeguard our data
    effectively.
  </p>
  <p class="font-24-16 text-light fw-normal">
    Let's take a closer look at how we ensure information security at FBSPL.
  </p>

  <div class="mt-5">
    <h3 class="text-primary font-48-30">Technical Controls</h3>
    <p class="font-24-16 text-light fw-normal">
      Our IT team has implemented the following technical controls to mitigate
      cyber security risks and protect FBSPL's data/information:
    </p>
    <ul class="font-24-16 text-light fw-normal">
      <li>
        Biometric turn-style door access. (Only authorized persons can go inside
        the premises).
      </li>
      <li>Implementation of UTM Firewall</li>
      <li>Endpoint security software</li>
      <li>Network level security</li>
      <li>Hardening of computer systems</li>
      <li>Azure hybrid network and mobile device management</li>
      <li>Network monitoring tools</li>
      <li>CCTV implementation and monitoring</li>
    </ul>
  </div>

  <div class="mt-5">
    <h3 class="text-primary font-48-30">Operational Control</h3>
    <p class="font-24-16 text-light fw-normal">
      Data breaches can come from multiple internal and external sources,
      including employees who fail to follow security procedures. We have hired
      a professional security guard to operate the ID system at the main gate to
      reduce these. These security guards take care of our physical security and
      ensure that only authorized people can enter the premises.
    </p>
    <p class="font-24-16 text-light fw-normal">
      In addition, our process excellence team helps us run a Cyber Security
      awareness program for our employees.
    </p>
  </div>

  <div class="mt-5">
    <h3 class="text-primary font-48-30">Managerial Control</h3>
    <p class="font-24-16 text-light fw-normal">
      Our cyber security has been designed and implemented by our management in
      coordination with the IT team, operation team, and all other departments.
    </p>
    <p class="font-24-16 text-light fw-normal">
      We have created SOPs for all departments to run the business smoothly and
      safely. Moreover, we use the NIST framework for internal risk assessments
      for our cyber security implementation and policies. We also hold ISO 27001
      certification and follow all regulatory protocols.
    </p>
  </div>

  <div class="mt-5">
    <h3 class="text-primary font-48-30">Disaster Management</h3>
    <p class="font-24-16 text-light fw-normal">
      We are prepared to fight any disaster that could disrupt the functioning
      of our businesses. As an IT company, we have devised a plan for disaster
      management of our computer systems, servers, and data or information.
    </p>
    <p class="font-24-16 text-light fw-normal">
      We do schedule preventive maintenance measures for all equipment and
      devices to ensure system safety from any failure.
    </p>
    <p class="font-24-16 text-light fw-normal">
      This disaster management is based on the risk assessment which we have
      done for our company.
    </p>
    <ul class="font-24-16 text-light fw-normal">
      <li>
        To prevent any power failure, we have installed 120 KVA Modular UPS.
      </li>
      <li>
        We have installed a 250 KVA DG set to provide power backup if the
        government electricity board's electrical supply fails.
      </li>
      <li>We have multiple internet lines coming from different ISPs</li>
      <li>
        We have the support of our capable and reliable vendors, who are always
        ready to provide support 24/7. We have signed non-disclosure agreements
        with them.
      </li>
      <li>
        We have a warm site at a different location where we can start our
        operation within 24 hours in case of any calamity.
      </li>
    </ul>

    <p class="font-24-16 text-light fw-normal">
      At FBSPL, cybersecurity is a practice that safeguards data integrity,
      ensures operational continuity, and upholds our clients' trust.
    </p>
  </div>
</section>
