/*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, Router } from '@angular/router';
import { OnlyNumbersDirective } from '../../../../src/app/directives/onlyNumbers.directive';
import { NoNumbersDirective } from '../../directives/noNumbers.directive';
import { EmailService } from '../../services/bookAConsultation_Email/bookAConsultation.component';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { HubspotService } from '../../services/hubspot/hubspot.service';
import {
  requiredButNotAllowBlank,
  customEmailValidator,
} from '../../utils/Validator';
import { CdnUrlDirective } from '../../directives/cdn-url.directive';
import { SubmitBtn } from '../SubmitBtn/SubmitBtn.component';
@Component({
  selector: 'MainForm',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    CdnUrlDirective,
    NoNumbersDirective,
    OnlyNumbersDirective,
    SubmitBtn
  ],
  templateUrl: './MainForm.component.html',
  styleUrl: './MainForm.component.css',
})
export class MainForm implements OnInit {
  consultationForm!: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private hubspotService: HubspotService,
    private emailService: EmailService // Inject EmailService
  ) {}
  ngOnInit(): void {
    this.consultationForm = this.formBuilder.group({
      firstname: ['', requiredButNotAllowBlank()],
      email: ['', [requiredButNotAllowBlank(), customEmailValidator]],
      company: ['', requiredButNotAllowBlank()],
      services: ['', requiredButNotAllowBlank()],
      phone: [''],
      country: ['', requiredButNotAllowBlank()],
      state: ['', requiredButNotAllowBlank()],
      write_your_message: ['', requiredButNotAllowBlank()],
      accept_policy: [false, Validators.requiredTrue],
    });
  }
  get consultationFormf() {
    return this.consultationForm.controls;
  }
  validateForm(form: FormGroup): void {
    this.submitted = true;
    if (this.consultationForm.invalid) {
      return;
    }
    const formId = '54cdb521-e6b8-423b-b30b-33438b3ad5ae';
    const formData = this.consultationForm.value;
    this.hubspotService.submitForm(formId, formData).subscribe(
      (response) => {
        console.log('Form submitted successfully:', response);
        this.router.navigate(['/book-free-consultation-call/thankyou']);
        // Send an email after successful submission
        this.emailService.sendEmail(formData).subscribe(
          (emailResponse) => {
            console.log('Email sent successfully:', emailResponse);
          },
          (error) => {
            console.error('Error sending email:', error);
          }
        );
      },
      (error) => {
        console.error('Error submitting form:', error);
        alert('Error submitting form. Please try again.');
      }
    );
  }
}
