<!-- /*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/ -->
@if (showComponent) {
<section class="bg-secondary position-relative" id="TalkToExpert">
  <img appCdnUrl="assets/Icon/rocket.png" class="rocket d-none d-md-block" />
  <div class="container py-6 text-center">
    <h4 class="text-primary font-48-30">Talk to our experts</h4>
    <p class="font-24-16 mb-md-5 mx-auto" style="max-width: 850px">
      Need immediate assistance? Talk to us. Our team is ready to help. Fill out
      the form below to connect.
    </p>
    <div class="mx-auto" style="max-width: 1050px">
      <MainForm></MainForm>
    </div>
  </div>
  <img
    appCdnUrl="assets/Icon/blue-square.svg"
    class="top-right-float vector mt-4"
  />
</section>
}
