/*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/
import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { SeoService } from '../../services/seo/seo.service';
import { SeoData } from '../../services/seo/seo-data.model';
import { FaqComponent } from '../../components/HomeComponents/faq/faq.component';
import { ServiceVimeoComponent } from '../../components/HomeComponents/service-vimeo/service-vimeo.component';
import { CdnUrlDirective } from '../../directives/cdn-url.directive';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { ourAssetsComponent } from '../../components/HomeComponents/ourAssets/ourAssets.component';
import { ThinkingSection } from '../../components/ThinkingSection/ThinkingSection.component';
import { SubmitBtn } from '../../components/SubmitBtn/SubmitBtn.component';

@Component({
  selector: 'app-main-service',
  standalone: true,
  imports: [
    RouterLink,
    RouterOutlet,
    FaqComponent,
    ServiceVimeoComponent,
    CdnUrlDirective,
    ourAssetsComponent,
    ThinkingSection,
    SubmitBtn
  ],
  templateUrl: './main-service.component.html',
  styleUrl: './main-service.component.css',
})
export class MainServiceComponent implements OnInit {
  constructor(
    private seoService: SeoService,
    private ngZone: NgZone,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener(
        'orientationchange',
        this.handleOrientationChange.bind(this)
      );
    }
  }
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title:
        'FBSPL - Business Process Outsourcing (BPO) Services |BPM Consultants',
      description:
        "FBSPL's expert consultation and BPM services. From Insurance, Ecommerce, RPO, Accounting & Bookkeeping to Customer Support and more. Contact Us!",
      keywords: 'bpo services, outsourcing solutions, bpm',
    };
    this.seoService.setMetaTags(seoData);
  }
  caseStudyData = {
    title: 'Insurance',
  };
  handleOrientationChange() {
    if (isPlatformBrowser(this.platformId)) {
      this.ngZone.run(() => {
        if (window.orientation === 0 || window.orientation === 180) {
          console.log('Portrait mode');
        } else if (window.orientation === 90 || window.orientation === -90) {
          console.log('Landscape mode');
        }
        // Refresh the page
        this.router.navigate([this.router.url]);
      });
    }
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
      // Check and add BreadcrumbList script
      if (
        !this.document.head.querySelector(
          'script[type="application/ld+json"][data-script="breadcrumb"]'
        )
      ) {
        const breadcrumbScript = this.renderer.createElement('script');
        breadcrumbScript.type = 'application/ld+json';
        breadcrumbScript.setAttribute('data-script', 'breadcrumb');
        breadcrumbScript.text = JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              name: 'Home',
              item: 'https://www.fbspl.com/',
            },
            {
              '@type': 'ListItem',
              position: 2,
              name: 'Services',
              item: 'https://www.fbspl.com/bpm-and-bpo-services',
            },
          ],
        });
        this.renderer.appendChild(this.document.head, breadcrumbScript);
      }

      // Check and add Service script
      if (
        !this.document.head.querySelector(
          'script[type="application/ld+json"][data-script="Service"]'
        )
      ) {
        const serviceScript = this.renderer.createElement('script');
        serviceScript.type = 'application/ld+json';
        serviceScript.setAttribute('data-script', 'Service');
        serviceScript.text = JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Service',
          serviceType: 'BPM & BPO Services',
          description:
            'Our Business Process Outsourcing (BPO) & Management Services include Insurance, Ecommerce, RPO, Accounting & Bookkeeping to Customer Support and more. Our UVPs are 24/7 availability, tool expertise, data protection, backup staff at no extra cost, and more.',
          provider: {
            '@type': 'Organization',
            name: 'FBSPL',
            url: 'https://www.fbspl.com',
            logo: 'https://d1585q1wairh36.cloudfront.net/assets/Logo/Logo.svg',
            contactPoint: {
              '@type': 'ContactPoint',
              telephone: '+1-240-979-0061',
              contactType: 'Customer Service',
              availableLanguage: 'English',
            },
          },
          url: 'https://www.fbspl.com/bpm-and-bpo-services',
          category: 'Business Services',
          areaServed: {
            '@type': 'Place',
            name: 'Global',
          },
          offers: {
            '@type': 'Offer',
            priceCurrency: 'USD',
            eligibleRegion: {
              '@type': 'Place',
              name: 'Global',
            },
          },
          hasOfferCatalog: {
            '@type': 'OfferCatalog',
            name: 'BPM & BPO Sub-Services',
            itemListElement: [
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  serviceType: 'Insurance Outsourcing Services',
                  url: 'https://www.fbspl.com/services/insurance-bpo-outsourcing',
                },
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  serviceType: 'Accounting & Bookkeeping Services',
                  url: 'https://www.fbspl.com/services/accounting-bookkeeping-services',
                },
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  serviceType: 'Data Annotation Services',
                  url: 'https://www.fbspl.com/services/data-annotation-services',
                },
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  serviceType: 'Recruitment Process Outsourcing',
                  url: 'https://www.fbspl.com/services/recruitment-process-outsourcing-services',
                },
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  serviceType: 'Customer Support Services',
                  url: 'https://www.fbspl.com/services/insurance-customer-support-services',
                },
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  serviceType: 'Digital Marketing',
                  url: 'https://www.fbspl.com/services/digital-marketing',
                },
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  serviceType: 'Insurance Agency Optimization',
                  url: 'https://www.fbspl.com/services/insurance-agency-optimization-services',
                },
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  serviceType: 'E-commerce Outsourcing Service',
                  url: 'https://www.fbspl.com/services/e-commerce-outsourcing-services',
                },
              },
            ],
          },
        });
        this.renderer.appendChild(this.document.head, serviceScript);
      }
    }
  }
}
